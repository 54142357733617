

































































import ServiceUtils, { Service } from '@/utils/ServiceUtils';
import { isArray } from 'lodash';
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';

interface ServiceOverride {
  value: boolean;
  label: string;
  items: Service[];
}

export default Vue.extend({
  props: {
    value: {
      type: Array as () => Service[],
    },
    label: {
      type: String as () => string,
    },
    errorMessages: {
      type: Array as () => string[],
    },
    messages: {
      type: [String as () => string, Array as () => string[]],
    },
    loading: {
      type: Boolean as () => boolean,
    },
    disabled: {
      type: Boolean as () => boolean,
    },
    multiple: {
      type: Boolean as () => boolean,
    },
    clearable: {
      type: Boolean as () => boolean,
    },

    override: {
      type: Object as () => ServiceOverride,
    },
  },

  computed: {
    internal: {
      get(): Service[] | undefined {
        return this.items?.filter((item) => {
          const values =
            !this.override || this.override?.value
              ? this.value
              : this.override.items;
          return values.some((value) => this.compare(item, value));
        });
      },
      set(item: Service[]) {
        this.$emit('input', item);
      },
    },

    overrideValue: {
      get(): boolean {
        return this.override?.value || false;
      },
      set(value: boolean) {
        if (this.override) {
          this.override.value = value;
          if (!this.override.value) {
            this.$emit('input', []);
          }
          this.$emit('override', this.override);
        }
      },
    },

    table(): { items?: Service[]; headers: DataTableHeader[] } {
      return {
        items: this.items,
        headers: [
          {
            text: this.$i18n.tc('service.key'),
            value: 'key',
          },
          {
            text: this.$i18n.tc('service.name'),
            value: 'name',
          },
          {
            text: '',
            value: 'actions',
          },
        ],
      };
    },
  },

  data(): {
    items?: Service[];
    modal: {
      visible?: 'add' | 'remove';
      items?: Service[];
      selected: Service[];
    };
  } {
    return {
      items: undefined,
      modal: {
        visible: undefined,
        items: undefined,
        selected: [],
      },
    };
  },

  async mounted() {
    try {
      this.items = await ServiceUtils.service.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    format(value: Service) {
      return `${this.$langobj.parse(value.name)}`;
    },

    compare(item: Service, value: Service) {
      if (isArray(item)) {
        return false;
      } else {
        return item.id === value?.id || item.key === value?.key;
      }
    },

    handleSubmit(selected: Service[]) {
      if (this.modal.visible === 'add') {
        this.internal = this.internal?.concat(
          selected.filter(
            (a) => (this.internal || [])?.findIndex((b) => a.id === b.id) < 0
          )
        );
      } else if (this.modal.visible === 'remove') {
        this.internal = this.internal?.filter(
          (a) => !selected?.some((b) => a.id === b.id)
        );
      }

      this.modal.visible = undefined; // close and clear modal
    },
  },

  watch: {
    'modal.visible': {
      handler(value) {
        if (!value) {
          this.modal.items = [];
          this.modal.selected = [];
        } else if (value === 'add') {
          this.modal.items = this.items?.filter(
            (a) => (this.value || [])?.findIndex((b) => a.id === b.id) < 0
          );
          this.modal.selected = [];
        } else if (value === 'remove') {
          this.modal.items = this.value;
          this.modal.selected = [];
        }
      },
    },
  },
});
